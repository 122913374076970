<template>
  <ion-page>
    <ion-content>
      <paid-component/>
      <main id="main" class="sitemain">
        <div class="page-title page-title--small align-left" style="background-color: #53bd6c!important;">
          <div class="container">
            <div class="page-title__content">
              <h1 class="page-title__name">Profil Düzenleme</h1>
              <p class="page-title__slogan">Burada profil bilgilerinizi düzenleyebilirsiniz.</p>
            </div>
          </div>
        </div>
      </main>

      <div class="site-content">
        <div class="checkout-area">
          <div class="container">
            <div class="row">

              <div class="col-lg-12">
                <div class="billing-form">
                  <h2>Profil düzenleme</h2>
                  <form ref="signup" action="#" @submit="sendForm" enctype="multipart/form-data">
                    <div class="field-group">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="field-input">
                            <input type="file" name="avatar" value="" ref="avatar" @change="setImage"
                                   style="display: none;"
                                   id="avatarFile" accept="image/png, image/jpeg, image/jpg"/>
                            <template v-if="avatar">
                              <img @click="openFileDialog" :src="avatar" alt="profile"
                                   style="width: 64px;
                                cursor:pointer;"/>
                            </template>
                            <template v-else>
                              <ion-button @click="openFileDialog">
                                <ion-icon slot="icon-only" :icon="camera"></ion-icon>
                              </ion-button>
                            </template>
                            <div
                                style="position:absolute;
                                left: 64px;
                                top: 48px;
                                background-color: black;
                                color: white;
                                border-radius: 32px;
                                width: 32px;
                                height: 32px;
                                cursor:pointer;
                                text-align: center;
                                line-height: 32px;
                                "
                                @click="removeAvatar">
                              X
                            </div>
                          </div>
                        </div>
                        <div class="col-md-10">
                          <div class="field-input">
                            <ion-label for="name">İsim Soyisim<span class="required">*</span></ion-label>
                            <ion-input type="text" name="name" id="name" v-model:value="profile.name"/>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="field-input">
                            <ion-label for="username">Kullanıcı Adı <span class="required">*</span></ion-label>
                            <ion-input type="text" name="username" id="username" :value="profile.username"/>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field-input">
                            <ion-label for="email">E-Posta <span class="required">*</span></ion-label>
                            <ion-input type="email" :value="profile.email" name="email" id="email"/>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field-input">
                            <ion-label for="phone_number">Telefon <span class="required">*</span></ion-label>
                            <ion-input type="text" :value="profile.phone_number" name="phone_number" id="phone_number"/>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="field-input">
                            <ion-label for="location">Lokasyon <span class="required">*</span></ion-label>
                            <GMapMap
                                :center="place.centerData"
                                :zoom="5"
                                map-type-id="terrain"
                                ref="map"
                                :options="mapOptions"
                                style="width: 100%; height: 20rem"
                            >
                              <GMapMarker
                                  :position="place.placeData"
                                  :clickable="true"
                                  :draggable="true"
                                  @dragend="markerEvent"
                              />
                            </GMapMap>
                            <ion-input hidden="hidden" name="location" v-model="place.placeDataForm"></ion-input>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field-input">
                            <ion-label for="company_name">Şirket Adı <span class="required">*</span></ion-label>
                            <ion-input type="text" :value="profile.company_name" name="company_name" id="company_name"/>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field-input">
                            <ion-label for="work_type_id">Şirket Tipi <span class="required">*</span></ion-label>
                            <ion-select interface="popover" id="work_type" name="select"
                                        @ionChange="selectWorkType" :value="parseInt(profile.work_type_id)">
                              <ion-select-option v-for="workType in workTypes" :key="workType.id" :value="workType.id"
                              >
                                {{ workType.name }}
                              </ion-select-option>
                            </ion-select>
                            <ion-input hidden="hidden" name="work_type_id" v-model="selectedWorkType"></ion-input>
                            <ion-input hidden="hidden" name="device_name" value="ionic"></ion-input>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="field-input">
                            <ion-label for="password">Şifrenizi girin</ion-label>
                            <ion-input type="password" value="" name="password" id="password"/>
                          </div>
                        </div>
                      </div>
                    </div><!-- .field-group -->


                    <div class="field-group">
                      <div class="field-check">
                      </div>
                    </div>
                    <div class="field-submit">

                      <ion-button type="submit" name="submit">
                        Kaydet
                      </ion-button>
                      <ion-button color="danger" @click="closeAccount">
                        Hesabı Kapat
                      </ion-button>
                      <ion-button v-if="profile.isVip" color="danger" @click="cancelSubscription">Aboneliği kapat
                      </ion-button>
                      <ion-button v-else-if="profile.subscription_status !== 'UNPAID'" @click="showVipPage"
                                  color="success">
                        Vip üye ol
                      </ion-button>
                    </div>
                    <div>
                      <br>
                        Hesabınızı hızlıca kapatmak istiyorsanız lütfen kendi telefon numaranız ile müşteri hizmetlerini; +(90) 850 532 9942 arayınız.
                    </div>
                  </form><!-- .billingForm -->
                </div><!-- .checkout-form -->

              </div>
            </div>
          </div>
        </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonButton,
  IonLabel,
  IonCheckbox,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonAlert, IonIcon
} from "@ionic/vue";
import {inject, ref} from "@vue/runtime-core";
import {useCookies} from "vue3-cookies";
import {camera} from "ionicons/icons";
import PaidComponent from "../components/PaidComponent.vue";

export default {
  name: "Profile",
  setup() {
    const $api = inject('api');
    const {cookies} = useCookies();
    const mapOptions = ref({
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#8ec3b9"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1a3646"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#4b6878"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#64779e"
            }
          ]
        },
        {
          "featureType": "administrative.province",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#4b6878"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#334e87"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#283d6a"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#6f9ba5"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#3C7680"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#304a7d"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#98a5be"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#2c6675"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#255763"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#b0d5ce"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#98a5be"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#283d6a"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#3a4762"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#0e1626"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#4e6d70"
            }
          ]
        }
      ]
    })
    const profile = ref({
      name: '',
      username: '',
      email: '',
      phone_number: '',
      company_name: '',
      location: '',
      work_type_id: '',
    });
    const place = ref({
      centerData: {lat: 39.94538951, lng: 32.94426233},
      placeData: {lat: 39.94538951, lng: 32.94426233},
      placeDataForm: '39.94538951, 32.94426233',
    });
    const selectedWorkType = ref('');
    const workTypes = ref([]);
    const consentContent = ref('');
    const consent = ref(false);
    const isOpen = ref(false);
    consentContent.value = $api.consent;
    workTypes.value = $api.workTypes;
    profile.value = $api.auth ?? profile.value;
    global.emitter.on('updateProfile', (profileData) => {
      profileData.then((profiler) => {
        profile.value = profiler;
      });
    })
    place.value.centerData = {lng: $api.auth?.location?.coordinates[0], lat: $api.auth?.location?.coordinates[1]};
    place.value.placeData = {lng: $api.auth?.location?.coordinates[0], lat: $api.auth?.location?.coordinates[1]};
    place.value.placeDataForm = $api.auth?.location?.coordinates[1] + ', ' + $api.auth?.location?.coordinates[0];
    selectedWorkType.value = $api.auth?.work_type_id;
    return {
      workTypes,
      consentContent,
      IonInput,
      IonCheckbox,
      IonLabel,
      IonButton,
      IonSelect,
      IonSelectOption,
      IonAlert,
      IonIcon,
      isOpen,
      consent,
      mapOptions,
      cookies,
      profile,
      selectedWorkType,
      camera,
      place
    };
  },
  components: {
    PaidComponent,
    IonPage,
    IonContent,
  },
  methods: {
    showVipPage() {
      this.$emitter.emit('calling_vip')
    },
    closeAccount() {
      let del = false;
      return this.$helper.setAlert('Hesabınızı kapatmak istediğinizden Emin Misiniz?', '', '',
          [{
            text: 'Evet', handler: () => {
              del = true;
            }
          }, {text: 'Hayır'}]
      ).then(async alert => {
        let _this = this;
        await alert.present();
        await alert.onDidDismiss();
        if (del) {
          this.$api.post('/close-account')
              .then((e) => {
                if (e.status !== 404) {
                  _this.$helper.setToast('Hesap kapatma isteğiniz alındı Şirket yetkilisi ile görüşülecektir.', 'dark').then(toast => toast.present());
                }
              })
              .catch((e) => {
                const message = this.helper.setErrorMessage(e.response);
                this.helper.setAlert('Hata', '', message).then(alert => alert.present());
                return e.response;
              });
        }
      });
    },
    cancelSubscription() {
      let del = false;
      return this.$helper.setAlert('Aboneliği kapatmak istediğinizden Emin Misiniz?', '', '',
          [{
            text: 'Evet', handler: () => {
              del = true;
            }
          }, {text: 'Hayır'}]
      ).then(async alert => {
        let _this = this;
        await alert.present();
        await alert.onDidDismiss();
        if (del) {
          this.$api.post('/cancel-vip')
              .then((e) => {
                if (e.status !== 404) {
                  _this.$helper.setToast('Abonelik durumu güncellendi!', 'dark').then(toast => toast.present());
                  _this.$emitter.emit('updateProfile', _this.$api.getAuth());
                }
              })
              .catch((e) => {
                const message = this.helper.setErrorMessage(e.response);
                this.helper.setAlert('Hata', '', message).then(alert => alert.present());
                return e.response;
              });
        }
      });
    },
    removeAvatar() {
      let _this = this;
      this.$api.get('remove-avatar').then(function (res) {
        if (res.status === 200) {
          _this.avatar = null;
          _this.$emitter.emit('updateProfile', _this.$api.getAuth());
        }
      });
    },
    openFileDialog() {
      document.getElementById("avatarFile").click();
    },
    setImage: function (e) {
      let f = e.target.files[0];
      const blob = new Blob([f])
      this.avatar = URL.createObjectURL(blob);
    },
    selectWorkType: function (e) {
      this.selectedWorkType = e.detail.value;
    },
    sendForm: function (e) {
      let _this = this;
      e.preventDefault();
      const formData = new FormData(e.target);
      if (formData.get('avatar').size === 0) {
        formData.delete('avatar');
      }
      if (!this.selectedWorkType) {
        this.$helper.setAlert(
            "Hata",
            '',
            "Lütfen bir çalışma tipi seçiniz",
            [{
              text: "Tamam"
            }]
        ).then(alert => alert.present());
        return;
      }
      this.$api.post('/profile', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(function (res) {
            if (res.status === 200) {
              _this.$helper.setToast('Profil Güncellendi', 'dark').then(toast => toast.present());
              _this.$emitter.emit('updateProfile', _this.$api.getAuth());
            }
          });
    },
    markerEvent: function (e) {
      const data = {lat: parseFloat(e.latLng.lat().toFixed(8)), lng: parseFloat(e.latLng.lng().toFixed(8))};
      this.place.centerData = data;
      this.place.placeData = data;
      this.place.placeDataForm = `${e.latLng.lat().toFixed(8)}, ${e.latLng.lng().toFixed(8)}`;
    },
  },
  data() {
    return {
      avatar: null,
    }
  },
  created() {
    this.avatar = this.$api.auth?.avatar;
  }
}
</script>

<style scoped>
</style>